<template>
    <div>
        <ValidationObserver ref="formModalValidate">
           <b-row>
                <b-col cols="12" md="6">
                  <ValidationProvider name="club_name" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('club_name')">
                            <b-form-input :validate-error="errors[0]" v-model="form.club_name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" /> 
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="club_type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('club_type')">
                            <parameter-selectbox v-model="form.club_type" :multiple="true"
                                                 :validate-error="errors[0]" code="club_type"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="form.status" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>   
                <b-col cols="12" md="6">
                    <ValidationProvider name="foundation_at" rules="required" v-slot="{ valid, errors }">
						<b-form-group :label="$t('foundation_at')">
							<b-form-input type="date"
							              v-model="form.foundation_at"
							              :state="errors[0] ? false : null"/>
							<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="club_president" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('club_president')">
                            <b-form-input :validate-error="errors[0]" v-model="form.club_president">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="acadmic_advisor" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('acadmic_advisor')">
                            <b-form-input :validate-error="errors[0]" v-model="form.acadmic_advisor">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="fee" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('fee')">
                            <b-form-input :validate-error="errors[0]" v-model="form.fee">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-button
                    @click="updateForm"
                    type="button"
                    :disabled="formLoading"
                    variant="primary"
                    class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </ValidationObserver>
    </div>
</template>

<script>
    //Component
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import StudentClubService from "@/services/StudentClubService";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            ParameterSelectbox,
            StatusSelectbox
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    //
                }
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                StudentClubService.get(id)
                               .then((response) => {
                                   this.form = response.data.data;
                                   this.formLoading = false;
                               })
                               .catch((error) => {
                                   if (error.data.message) {
                                       this.$toast.error(this.$t("api." + error.data.message));
                                   }
                               });
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    StudentClubService.update(this.formId, this.form)
                                   .then((response) => {
                                       this.formLoading=false;
                                       this.$toast.success(this.$t("api." + response.data.message));
                                       this.$emit("updateFormSuccess")
                                   })
                                   .catch((error) => {
                                       this.showErrors(error, this.$refs.formModalValidate)
                                   })
                }
            }
        }
    }
</script>
